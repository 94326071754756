<template>
  <div class="container-fluid py-3">
    <vue-progress-bar></vue-progress-bar>
    <div class="row">
      <div class="col-12">
        <div class="card" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_NOURISHED_PLAN') && $store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_SHOW_LIST')">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Początek okresu <span class="text-danger">*</span></label>
                  <datetime v-model="dateFrom" type="date" format="yyyy-MM-dd" value-zone="local" zone="local"></datetime>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Koniec okresu <span class="text-danger">*</span></label>
                  <datetime v-model="dateTo" type="date" format="yyyy-MM-dd" value-zone="local" zone="local"></datetime>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label for="selectedMeals">Posiłki <span class="text-danger">*</span></label>
                <multiselect v-model.trim="selectedMeals" :options="mealsList" :custom-label="mealLabel" :multiple="true" :allow-empty="false" :close-on-select="false" :clear-on-select="false" :preserve-search="true" track-by="name" name="selectedMeals" placeholder="Wybierz posiłek" select-label="Wybierz" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span v-if="values.length && !isOpen" class="multiselect__single">Wybranych posiłków: {{ values.length }}</span>
                  </template>
                </multiselect>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="hasNotEmptyCols">Ukryj puste kolumny</label>
                  <multiselect v-model.trim="hasNotEmptyCols" :options="hasNotEmptyColsOptions" :custom-label="hasNotEmptyColsLabel" :allow-empty="false" track-by="name" name="hasNotEmptyCols" placeholder="Wybierz czy ukrywać puste kolumny" select-label="Wybierz" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required></multiselect>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 align-self-end text-right">
                <button class="btn btn-success btn-sm" @click="downloadReport('xls')" :disabled="disabledBtn">Pobierz XLS</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card p-0 m-0 border-0" v-else>
          <div class="alert alert-warning alert--grouped">
            <i class="fe fe-alert-triangle mr-2"></i>
            Uwaga!<br />Zasób nie istnieje lub nie masz do niego dostępu.
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="processing" class="loader my-4"></div>
      </div>
    </div>
  </div><!-- /container-fluid -->
</template>

<script>
import { axios_instance as axios } from "@/axios-config.js";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import moment from "moment";
import Multiselect from "vue-multiselect";
export default {
  components: {
    datetime: Datetime,
    Multiselect,
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      hasNotEmptyCols: null,
      hasNotEmptyColsOptions: [
        { name: "Tak", value: true },
        { name: "Nie", value: false },
      ],
      mealsList: [],
      selectedMeals: null,
      processing: false,
      defaultSelectText: null,
    };
  },
  created() {
    this.getMeals();
  },
  methods: {
    mealLabel({ name }) {
      return `${name} `;
    },
    hasNotEmptyColsLabel({ name }) {
      return `${name} `;
    },
    async getMeals() {
      const resource = "app/d_meals?context=dMeal";
      try {
        this.$Progress.start();
        const { data } = await axios.get(resource);
        this.mealsList = data["hydra:member"];
        this.$Progress.finish();
      } catch (error) {
        console.log(error);
        this.$Progress.fail();
      }
    },
    downloadReport() {
      this.processing = true;
      this.$Progress.start();

      let startDate = moment(this.dateFrom).format("YYYY-MM-DD");
      let endDate = moment(this.dateTo).format("YYYY-MM-DD");
      let mealGroup = [];

      this.selectedMeals.forEach((item) => {
        mealGroup.push(item["uuid"]);
      });

      let jsonToSend = {
        dateFrom: startDate,
        dateTo: endDate,
        withoutEmptyColumns: this.hasNotEmptyCols.value,
        mealGroup: mealGroup,
        type: "xls",
      };

      axios
        .post(
          `app/report/nourished-plan?context=reportNourishedPlan`,
          jsonToSend,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          return response;
        })
        .then((blob) => {
          blob = new Blob([blob.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "RIZ_" + startDate + "_" + endDate;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.processing = false;
          this.$Progress.finish();
        });
    },
  },
  computed: {
    disabledBtn() {
      if (
        this.dateFrom &&
        this.dateTo &&
        this.hasNotEmptyCols &&
        this.selectedMeals
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables";
@import "../../assets/css/scoped-forms";
</style>
